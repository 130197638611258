import React from "react";
import s from './FeatureWrapper.module.css';
import FeatureWrapperBackgroundHex1 from "../../images/FeatureWrapperBackgroundHex1.svg";
import FeatureWrapperBackgroundHex2 from "../../images/FeatureWrapperBackgroundHex2.svg";
import FeatureWrapperBackgroundHex3 from "../../images/FeatureWrapperBackgroundHex3.svg";

const FeatureWrapper = () => {
    return    <div className={s.FeatureWrapper}>
        <div className={s.FeatureWrapperBackgroundHex1}>
            <img className={s.animation1} src={FeatureWrapperBackgroundHex1}/>
        </div><
        div className={s.FeatureWrapperBackgroundHex2}>
            <img className={s.animation1} src={FeatureWrapperBackgroundHex1}/>
        </div>
        <div className={s.FeatureWrapperBackgroundHex3}>
            <img className={s.animation1} src={FeatureWrapperBackgroundHex2}/>
        </div>
        <div className={s.FeatureWrapperBackgroundHex4}>
            <img className={s.animation1} src={FeatureWrapperBackgroundHex3}/>
        </div>
        <div className={s.FeatureWrapperBackgroundHex5}>
            <img className={s.animation1} src={FeatureWrapperBackgroundHex2}/>
        </div>
        <div className={s.FeatureWrapperBackgroundHex6}>
            <img className={s.animation1} src={FeatureWrapperBackgroundHex2}/>
        </div>
    </div>
}
export default FeatureWrapper;