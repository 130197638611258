import React from "react";
import s from './Feature.module.css';
import featureHex1 from "../../images/featureHex1.svg";
import featureHex2 from "../../images/featureHex2.svg";
import featureHex3 from "../../images/featureHex3.svg";
import featureHex4 from "../../images/featureHex4.svg";

const Feature = () => {
    return <article id="feature">
        <div className={s.featureWrapper}>
            <div className={s.hex1}>
                <img src={featureHex1}/>
            </div>
            <div className={s.hex2}>
                <img src={featureHex2}/>
            </div>
            <div className={s.hex3}>
                <img src={featureHex3}/>
            </div>
            <div className={s.hex4}>
                <img src={featureHex4}/>
            </div>

            <div className={s.feature}>
                <div className={s.title}> Фичи</div>

                <div className={s.head1}> Жанровое сплетение</div>
                <div className={s.text1}>
                    Уникальное сочетание жанров стратегии реального времени и
                    карточной коллекционной игры в стиле научной фантастики. <br/><br/>
                    Жанры разделены на режимы исследования и сражения, тесно
                    взаимосвязанные в игре
                </div>

                <div className={s.head2}> Искажение пространства</div>
                <div className={s.text2}>
                    Мир постепенно заполняется искажениями от Рифтов – главных
                    антагонистов игры – из-за чего происходят аномалии и разрушается
                    пространство
                </div>

                <div className={s.head3}> Комбинирование PVE и PVP</div>
                <div className={s.text3}>
                    Тут каждый найдет себе вариант по вкусу:
                    исследовать PVE локации в одиночку или с друзьями, изучать
                    механики и вчитываться в сюжет или же сражаться с другими
                    игроками в PVP поединках и на
                    турнирах – скучать в игре вы точно не будете
                </div>

                <div className={s.head4}> Пиксельная и растровая графика</div>
                <div className={s.text4}>
                    Визуальное оформление игры выполнено одновременно в стиле
                    pixel-art и digital-art, что вносит разнообразие в дизайн игры
                </div>
            </div>
        </div>
    </article>
}

export default Feature;