import React, {Component, useEffect, useState} from "react";
import axios from 'axios';
import s from './Follow_us.module.css';
import dtf from "../../images/DTF.svg";
import tg from "../../images/Telegram.svg";
import vk from "../../images/VK.svg";
import ds from "../../images/Discord.svg";
import PostForm from "./PostForm/PostForm";

const Follow_us = () => {
    return (<article id="follow_us">
        <div className={s.follow_us}>

            <PostForm/>

            {/*<div className={s.title}>*/}
            {/*    Следите за нами*/}
            {/*</div>*/}

            {/*<div className={s.text1}>*/}
            {/*    Подпишитесь на новости о проекте*/}
            {/*</div>*/}

            {/*<div className={s.text4}>*/}
            {/*    Социальные сети проекта:*/}
            {/*</div>*/}

            {/*<div className={s.socNet1}>*/}
            {/*    <a href="https://dtf.ru/s/638231-eternal-rifts" target="_blank">*/}
            {/*        <img src={dtf} alt=""/>*/}
            {/*    </a>*/}
            {/*</div>*/}

            {/*<div className={s.socNet2}>*/}
            {/*    <a href="https://t.me/eternalrifts" target="_blank">*/}
            {/*        <img src={tg} alt=""/>*/}
            {/*    </a>*/}
            {/*</div>*/}

            {/*<div className={s.socNet3}>*/}
            {/*    <a href="https://vk.com/eternalrifts" target="_blank">*/}
            {/*        <img src={vk} alt=""/>*/}
            {/*    </a>*/}
            {/*</div>*/}

            {/*<div className={s.socNet4}>*/}
            {/*    <a href="https://discord.gg/acXeghy6tU" target="_blank">*/}
            {/*        <img src={ds} alt=""/>*/}
            {/*    </a>*/}
            {/*</div>*/}

            {/*<div className={s.rectangle2}>*/}
            {/*    /!*<div className={s.text2}>*!/*/}
            {/*    /!*    example@yandex.ru*!/*/}
            {/*    /!*</div>*!/*/}
            {/*</div>*/}

            {/*<input className={s.example1} name="user" placeholder="example@yandex.ru" value={this.state.user}*/}
            {/*       onChange={this.handleChange}/>*/}
            {/*<button className={s.button1} onSubmit={this.handleFormSubmit} type="submit">Sign In</button>*/}

            {/*<form className={s.example11} onSubmit={this.handleFormSubmit}>*/}
                {/*<input name="email" value={this.state.email}/>*/}

            {/*    <button type="submit" className={s.button1} name="field2" id="button1">*/}
            {/*        Подписаться*/}
            {/*    </button>*/}
            {/*</form>*/}
            {/*<div className={s.example1}>*/}
            {/*    <input type="email" placeholder="example@yandex.ru" name="field1" id="email1"/>*/}

            {/*</div>*/}


        </div>

    </article>
)
    ;
}


export default Follow_us;
