import React from "react";
import s from './AboutAdv2.module.css';
import Polygon62 from "../../images/Polygon62.png";
import Polygon63 from "../../images/Polygon63.png";
import {NavLink} from "react-router-dom";

const AboutAdv2 = () => {
    return <div className={s.aboutAdv2}>
        <div className={s.button1}>
            <button className={s.button} role="button">
                <NavLink to="/" style={({ isActive, isPending, isTransitioning }) => {
                    return {
                        fontWeight: isActive ? "bold" : "",
                        color: isPending ? "red" : "white",
                        viewTransitionName: isTransitioning ? "slide" : "",
                    };
                }}>
                    Что произошло?

                </NavLink>
            </button>
        </div>
        {/*<div className={s.rectangle1}></div>*/}
        {/*<div className={s.text1}>*/}
        {/*    Что произошло?*/}
        {/*</div>*/}

        <div className={s.title}>
            Начало игры
        </div>

        <div className={s.text2}>
            В начале игры необходимо выбрать класс персонажа, который
            будет соответствовать вашему стилю игры. На выбор
            влияют уникальная способность класса и соответствующие ему
            таланты. Дерево талантов разблокируется по мере прохождения игры.
        </div>
        <div className={s.text3}>
            Для получения опыта необходимо:
        </div>

        <div className={s.img1}>
            <div className={s.animation1}>

                <img src={Polygon62} alt=""/>
            </div>
        </div>
        <div className={s.text4}>
            исследовать карту
        </div>

        <div className={s.img2}>
            <div className={s.animation1}>

                <img src={Polygon63} alt=""/>
            </div>
        </div>
        <div className={s.text5}>
            участвовать в сражениях
        </div>

        <div className={s.img3}>
            <div className={s.animation1}>

                <img src={Polygon63} alt=""/>
            </div>
        </div>
        <div className={s.text6}>
            зачищать постройки
        </div>

        <div className={s.rectangle2}></div>
        <div className={s.text7}>
            Уничтожение Рифтов - ваша главная задача
        </div>
        <div className={s.text8}>
            Для ее выполнения вам нужно собрать колоду карточек -
            отряд существ из разных вселенных. Стартовая колода
            дополняется и усиливается в процессе игры. Ваш отряд можно
            пополнить в особых зданиях наемников за ресурсы.
        </div>

        <div className={s.text9}>
            Снаряжение
        </div>

        <div className={s.text10}>
            Сила отряда зависит не только от ваших существ, но и от
            снаряжения игрока. Эффекты предметов могут быть совершенно
            разными: <span className={s.text12}>от увеличения силы атаки ваших существ до ядерного
            удара по войску противника</span>. Также, хорошее снаряжение имеет
            стратегическое значение при исследовании карты. Предметы можно
            отбивать у врагов, находить в заброшенных зданиях или приобретать
            в торговых пунктах.
        </div>
        <div className={s.button2}>
            <button className={s.button} role="button">
                <a href="#about">
                    <NavLink to="/aboutAdv3" style={({ isActive, isPending, isTransitioning }) => {
                        return {
                            fontWeight: isActive ? "bold" : "",
                            color: isPending ? "red" : "white",
                            viewTransitionName: isTransitioning ? "slide" : "",
                        };
                    }} >
                        Сражения
                    </NavLink>
                </a>
            </button>
            {/*<div className={s.rectangle3}></div>*/}
            {/*<div className={s.text11}>*/}
            {/*    Сражения*/}
            {/*</div>*/}

        </div>
    </div>
}
export default AboutAdv2;