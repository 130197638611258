import React from "react";
import s from './Header.module.css';
import header_logo from '../../images/header_logo.svg';
import logo_dtf from '../../images/logo_dtf.svg';
import logo_tg from '../../images/logo_tg.svg';
import logo_vk from '../../images/logo_vk.svg';
import logo_ds from '../../images/logo_ds.svg';

const Header = () => {
    return <div className={s.head}>

        <div className={s.header_logo}>
            <img src={header_logo} alt="" />
        </div>

        <div className={s.navbar}>
            <a className={s.navLink} href="#about">Об игре </a>
            {/*<a className={s.navLink} href="#part2">Разработчики </a>*/}
            <a className={s.navLink} href="http://www.montecegaming.com" target="_blank">
                Разработчики
            </a>
            <a className={s.navLink} href="#roadmap">Прогресс разработки </a>
            <a className={s.navLink} href="#feature">Фичи </a>
            {/*<a className={s.navLink} href="#follow_us">Следить за проектом</a>*/}

        </div>

        <div className={s.header_socNet}>
            <a href="https://dtf.ru/s/638231-eternal-rifts" target="_blank">
                <img src={logo_dtf} alt="" />
            </a>

            <a href="https://t.me/eternalrifts" target="_blank">
                <img src={logo_tg} alt="" />
            </a>

            <a href="https://vk.com/eternalrifts" target="_blank">
                <img src={logo_vk} alt="" />
            </a>

            <a href="https://discord.gg/acXeghy6tU" target="_blank">
                <img src={logo_ds} alt="" />
            </a>
        </div>
    </div>
}
export default Header;