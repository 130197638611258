import React from "react";
import s from './RoadmapWrapper2.module.css';
import RoadmapWrapperBackgroundHex4 from "../../images/RoadmapWrapperBackgroundHex4.svg";
import RoadmapWrapperBackgroundHex5 from "../../images/RoadmapWrapperBackgroundHex5.svg";

const RoadmapWrapper2 = () => {
    return    <div className={s.RoadmapWrapper2}>
        <div className={s.RoadmapWrapperBackgroundHex5}>
            <img className={s.animation1} src={RoadmapWrapperBackgroundHex4}/>
        </div>
        <div className={s.RoadmapWrapperBackgroundHex6}>
            <img className={s.animation1} src={RoadmapWrapperBackgroundHex4}/>
        </div>
        <div className={s.RoadmapWrapperBackgroundHex7}>
            <img className={s.animation1} src={RoadmapWrapperBackgroundHex4}/>
        </div>
        <div className={s.RoadmapWrapperBackgroundHex8}>
            <img className={s.animation1} src={RoadmapWrapperBackgroundHex5}/>
        </div>
        <div className={s.RoadmapWrapperBackgroundHex9}>
            <img className={s.animation1} src={RoadmapWrapperBackgroundHex4}/>
        </div>
    </div>
}
export default RoadmapWrapper2;