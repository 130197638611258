import React from "react";
import s from './About.module.css';
import aboutBackgroundHex1 from "../../images/aboutBackgroundHex1.svg";
import aboutBackgroundHex2 from "../../images/aboutBackgroundHex2.svg";
import aboutBackgroundHex3 from "../../images/aboutBackgroundHex3.svg";
import aboutBackgroundHex4 from "../../images/aboutBackgroundHex4.svg";
import aboutBackgroundHex5 from "../../images/aboutBackgroundHex5.svg";

const About = () => {
    return <article id="about">
        <div className={s.about}>
            <div className={s.title}>Об игре</div>

            <div className={s.description}>Вам предстоит путешествовать по мультивселенной в одиночку или с друзьями и
                собирать отряд для борьбы с Рифтами, чья единственная цель - пожирать другие реальности
            </div>

            <svg  className={s.animation1} width="307" height="353" viewBox="0 0 307 353" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.64651 91.1367L153.5 5.7735L301.353 91.1367V261.863L153.5 347.226L5.64651 261.863V91.1367Z" fill="url(#paint0_linear_107_1507)" stroke="url(#paint1_linear_107_1507)" stroke-width="10"/>
                <defs>
                    <linearGradient id="paint0_linear_107_1507" x1="153.5" y1="0" x2="153.5" y2="353" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#56495C"/>
                        <stop offset="1" stop-color="#032033"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_107_1507" x1="153.5" y1="0" x2="153.5" y2="353" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#93EEEE"/>
                        <stop offset="1" stop-color="white"/>
                    </linearGradient>
                </defs>
            </svg>

            <svg className={s.animation2} width="307" height="353" viewBox="0 0 307 353" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.64651 91.1367L153.5 5.7735L301.353 91.1367V261.863L153.5 347.226L5.64651 261.863V91.1367Z" fill="white"/>
                <path d="M5.64651 91.1367L153.5 5.7735L301.353 91.1367V261.863L153.5 347.226L5.64651 261.863V91.1367Z" fill="url(#paint0_linear_107_1513)"/>
                <path d="M5.64651 91.1367L153.5 5.7735L301.353 91.1367V261.863L153.5 347.226L5.64651 261.863V91.1367Z" stroke="url(#paint1_linear_107_1513)" stroke-width="10"/>
                <defs>
                    <linearGradient id="paint0_linear_107_1513" x1="153.5" y1="0" x2="153.5" y2="353" gradientUnits="userSpaceOnUse">
                        <stop stop-color="white"/>
                        <stop offset="1" stop-color="#93EFEF"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_107_1513" x1="153.5" y1="0" x2="153.5" y2="353" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#56495C"/>
                        <stop offset="1" stop-color="#021F32"/>
                    </linearGradient>
                </defs>
            </svg>

            <svg className={s.animation3} width="307" height="353" viewBox="0 0 307 353" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.64651 91.1367L153.5 5.7735L301.353 91.1367V261.863L153.5 347.226L5.64651 261.863V91.1367Z" fill="url(#paint0_linear_107_1510)" stroke="url(#paint1_linear_107_1510)" stroke-width="10"/>
                <defs>
                    <linearGradient id="paint0_linear_107_1510" x1="153.5" y1="0" x2="153.5" y2="353" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#56495C"/>    
                        <stop offset="1" stop-color="#032033"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_107_1510" x1="153.5" y1="0" x2="153.5" y2="353" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#93EEEE"/>
                        <stop offset="1" stop-color="white"/>
                    </linearGradient>
                </defs>
            </svg>

                    <div className={s.text1}>Прокачивайте<br/>навыки</div>

                    <div className={s.text2}>Зачищайте<br/>комплексы</div>

                    <div className={s.text3}>Исследуйте<br/>миры</div>

                    <div className={s.rectangle_3}></div>
                    <div className={s.rectangle_4}></div>

                    <div className={s.zadacha}>
                        Ваша задача - уничтожить разломы, из который появляются сущности, порожденные Рифтами. А если не
                        справляетесь - призывайте на помощь других бойцов со всего мира!
                    </div>
            </div>
    </article>
}
export default About;