import React from "react";
import s from './AboutAdv1Wrapper.module.css';
import AboutAdv1WrapperBackgroundHex1 from "../../images/AboutAdv1WrapperBackgroundHex1.svg";
import AboutAdv1WrapperBackgroundHex2 from "../../images/AboutAdv1WrapperBackgroundHex2.svg";
import AboutAdv1WrapperBackgroundHex3 from "../../images/AboutAdv1WrapperBackgroundHex3.svg";
import AboutAdv1WrapperBackgroundHex4 from "../../images/AboutAdv1WrapperBackgroundHex4.svg";
import AboutAdv1WrapperBackgroundHex5 from "../../images/AboutAdv1WrapperBackgroundHex5.svg";

const AboutAdv1Wrapper = () => {
    return <article id="aboutAdv">
        <div className={s.AboutAdv1Wrapper}>
            <div className={s.AboutAdv1WrapperBackgroundHex1}>
                <img className={s.animation1} src={AboutAdv1WrapperBackgroundHex1}/>
            </div>
            <div className={s.AboutAdv1WrapperBackgroundHex2}>
                <img className={s.animation1} src={AboutAdv1WrapperBackgroundHex2}/>
            </div>
            <div className={s.AboutAdv1WrapperBackgroundHex3}>
                <img className={s.animation1} src={AboutAdv1WrapperBackgroundHex3}/>
            </div>
            <div className={s.AboutAdv1WrapperBackgroundHex4}>
                <img className={s.animation1} src={AboutAdv1WrapperBackgroundHex4}/>
            </div>
            <div className={s.AboutAdv1WrapperBackgroundHex5}>
                <img className={s.animation1} src={AboutAdv1WrapperBackgroundHex5}/>
            </div>
        </div>
    </article>
}
export default AboutAdv1Wrapper;