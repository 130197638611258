import React from "react";
import s from './AboutAdv1.module.css';
import AboutAdv1Hex1 from "../../images/AboutAdv1Hex1.svg";
import AboutAdv1Hex2 from "../../images/AboutAdv1Hex2.svg";
import {NavLink} from "react-router-dom";

const AboutAdv1 = () => {
    return <div className={s.aboutAdv1}>
        <div className={s.title}>
            Что произошло?
        </div>

        <div className={s.text1}>
            Над мирами Eternal Rifts нависла страшная угроза. Существа, именуемые Рифтами, поглощают нашу
            мультивселенную
        </div>

        <div className={s.text2}>
            Вам необходимо путешествовать по планетам, собирать предметы, прокачивать навыки, исследовать постройки
            и
            призывать в свой отряд существ из различных вселенных для выполнения главной задачи: уничтожение Рифтов
            -
            сущностей, появляющихся из пространственных разломов
        </div>
        <div className={s.text3}>
            Устанавливайте контакт с другими игроками:
        </div>

        <div className={s.img1}>
            <div className={s.animation1}>
                <img src={AboutAdv1Hex1} alt=""/>
            </div>
        </div>
        <div className={s.text4}>
            объединяйтесь для сохранения мира
        </div>

        <div className={s.img2}>
            <div className={s.animation1}>
                <img src={AboutAdv1Hex2} alt=""/>
            </div>
        </div>
        <div className={s.text5}>
            уничтожайте друг друга в борьбе за ресурсы
        </div>
        <div className={s.button1}>
            <button className={s.button} role="button">
                <a href="#about">
                    <NavLink
                        className={s.navLink}
                        to="/aboutAdv2"
                        style={({ isActive, isPending, isTransitioning }) => {
                            return {
                                fontWeight: isActive ? "bold" : "",
                                color: isPending ? "red" : "white",
                                viewTransitionName: isTransitioning ? "slide" : "",
                            };
                        }}
                    >
                      О начале игры
                    </NavLink>
                </a>
            </button>
        </div>

    </div>
}
export default AboutAdv1;