import React from "react";
import s from './Follow_usWrapper.module.css';
// import MainBackgroundHex1 from "../../images/MainBackgroundHex1.svg";
// import MainBackgroundHex2 from "../../images/MainBackgroundHex2.svg";
// import MainBackgroundHex3 from "../../images/MainBackgroundHex3.svg";
import group1 from "../../images/group1.svg";
import group2 from "../../images/group2.svg";


const Follow_usWrapper = () => {
    return <div className={s.Follow_usWrapper}>
        <link rel="stylesheet" media="(max-width: 700px)" href="example.css" />
        <div className={s.group1}>
            <img className={s.animation1} src={group1}/>
        </div>

        <div className={s.group2}>
            <img className={s.animation1} src={group2}/>
        </div>

        {/*<div className={s.MainBackgroundHex1}>*/}
        {/*    <img className={s.animation1} src={MainBackgroundHex1}/>*/}
        {/*</div>*/}
        {/*<div className={s.MainBackgroundHex2}>*/}
        {/*    <img className={s.animation1} src={MainBackgroundHex2}/>*/}
        {/*</div>*/}
        {/*<div className={s.MainBackgroundHex3}>*/}
        {/*    <img className={s.animation1} src={MainBackgroundHex3}/>*/}
        {/*</div>*/}
        {/*<div className={s.MainBackgroundHex4}>*/}
        {/*    <img className={s.animation1} src={MainBackgroundHex2}/>*/}
        {/*</div>*/}
        {/*<div className={s.MainBackgroundHex5}>*/}
        {/*    <img className={s.animation1} src={MainBackgroundHex2}/>*/}
        {/*</div>*/}
        {/*<div className={s.MainBackgroundHex6}>*/}
        {/*    <img className={s.animation1} src={MainBackgroundHex2}/>*/}
        {/*</div>*/}
        {/*<div className={s.MainBackgroundHex7}>*/}
        {/*    <img className={s.animation1} src={MainBackgroundHex2}/>*/}
        {/*</div>*/}

        {/*<div className={s.MainBackgroundHex8}>*/}
        {/*    <img className={s.animation1} src={MainBackgroundHex2}/>*/}
        {/*</div>*/}

        {/*<div className={s.MainBackgroundHex9}>*/}
        {/*    <img className={s.animation1} src={MainBackgroundHex2}/>*/}
        {/*</div>*/}

        {/*<div className={s.MainBackgroundHex10}>*/}
        {/*    <img className={s.animation1} src={MainBackgroundHex2}/>*/}
        {/*</div>*/}

        {/*<div className={s.MainBackgroundHex11}>*/}
        {/*    <img className={s.animation1} src={MainBackgroundHex2}/>*/}
        {/*</div>*/}

        {/*<div className={s.MainBackgroundHex12}>*/}
        {/*    <img className={s.animation1} src={MainBackgroundHex2}/>*/}
        {/*</div>*/}

        {/*<div className={s.MainBackgroundHex13}>*/}
        {/*    <img className={s.animation1} src={MainBackgroundHex2}/>*/}
        {/*</div>*/}

        {/*<div className={s.MainBackgroundHex14}>*/}
        {/*    <img className={s.animation1} src={MainBackgroundHex2}/>*/}
        {/*</div>*/}

        {/*<div className={s.MainBackgroundHex15}>*/}
        {/*    <img className={s.animation1} src={MainBackgroundHex2}/>*/}
        {/*</div>*/}

        {/*<div className={s.MainBackgroundHex16}>*/}
        {/*    <img className={s.animation1} src={MainBackgroundHex2}/>*/}
        {/*</div>*/}

        {/*<div className={s.MainBackgroundHex17}>*/}
        {/*    <img className={s.animation1} src={MainBackgroundHex2}/>*/}
        {/*</div>*/}

        {/*<div className={s.MainBackgroundHex18}>*/}
        {/*    <img className={s.animation1} src={MainBackgroundHex2}/>*/}
        {/*</div>*/}

        {/*<div className={s.MainBackgroundHex19}>*/}
        {/*    <img className={s.animation1} src={MainBackgroundHex2}/>*/}
        {/*</div>*/}

    </div>
}
export default Follow_usWrapper;