import React from "react";
import s from './RoadmapWrapper1.module.css';
import RoadmapWrapperBackgroundHex1 from "../../images/RoadmapWrapperBackgroundHex1.svg";
import RoadmapWrapperBackgroundHex2 from "../../images/RoadmapWrapperBackgroundHex2.svg";
import RoadmapWrapperBackgroundHex3 from "../../images/RoadmapWrapperBackgroundHex3.svg";

const RoadmapWrapper1 = () => {
    return    <div className={s.RoadmapWrapper1}>
        <div className={s.RoadmapWrapperBackgroundHex1}>
            <img className={s.animation1} src={RoadmapWrapperBackgroundHex1}/>
        </div><
        div className={s.RoadmapWrapperBackgroundHex2}>
            <img className={s.animation1} src={RoadmapWrapperBackgroundHex2}/>
        </div>
        <div className={s.RoadmapWrapperBackgroundHex3}>
            <img className={s.animation1} src={RoadmapWrapperBackgroundHex3}/>
        </div>
        <div className={s.RoadmapWrapperBackgroundHex4}>
            <img className={s.animation1} src={RoadmapWrapperBackgroundHex3}/>
        </div>
    </div>
}
export default RoadmapWrapper1;