import React from "react";
import s from './AboutWrapper.module.css';
import AboutWrapperBackgroundHex1 from "../../images/AboutWrapperBackgroundHex1.svg";
import AboutWrapperBackgroundHex2 from "../../images/AboutWrapperBackgroundHex2.svg";
import AboutWrapperBackgroundHex3 from "../../images/AboutWrapperBackgroundHex3.svg";
import AboutWrapperBackgroundHex4 from "../../images/AboutWrapperBackgroundHex4.svg";
import AboutWrapperBackgroundHex5 from "../../images/AboutWrapperBackgroundHex5.svg";

const AboutWrapper = () => {
    return    <div className={s.AboutWrapper}>
        <div className={s.AboutWrapperBackgroundHex1}>
            <img className={s.animation1} src={AboutWrapperBackgroundHex1}/>
        </div>
        <div className={s.AboutWrapperBackgroundHex2}>
            <img className={s.animation1} src={AboutWrapperBackgroundHex2}/>
        </div>
        <div className={s.AboutWrapperBackgroundHex3}>
            <img className={s.animation1} src={AboutWrapperBackgroundHex3}/>
        </div>
        <div className={s.AboutWrapperBackgroundHex4}>
            <img className={s.animation1} src={AboutWrapperBackgroundHex4}/>
        </div>
        <div className={s.AboutWrapperBackgroundHex5}>
            <img className={s.animation1} src={AboutWrapperBackgroundHex5}/>
        </div>
    </div>
}
export default AboutWrapper;