import React from "react";
import './App.css';
import Header from "./components/Header/Header";
import Main_page from "./components/Main_page/Main_page";
import About from "./components/About/About";
import AboutAdv1 from "./components/AboutAdv1/AboutAdv1";
import AboutAdv2 from "./components/AboutAdv2/AboutAdv2";
import AboutAdv3 from "./components/AboutAdv3/AboutAdv3";
import Feature from "./components/Feature/Feature";
import Roadmap from "./components/Roadmap/Roadmap";
import Follow_us from "./components/Follow_us/Follow_us";
import Footer from "./components/Footer/Footer";
import BackgroundHexWrapper from "./components/backgroundHexWrapper/backgroundHexWrapper";
import AboutAdv1Wrapper from "./components/AboutAdv1Wrapper/AboutAdv1Wrapper";
import AboutWrapper from "./components/AboutWrapper/AboutWrapper";
import FeatureWrapper from "./components/FeatureWrapper/FeatureWrapper";
import RoadmapWrapper2 from "./components/RoadmapWrapper2/RoadmapWrapper2";
import RoadmapWrapper1 from "./components/RoadmapWrapper1/RoadmapWrapper1";
import Follow_usWrapper from "./components/Follow_usWrapper/Follow_usWrapper";
import Main_pageWrapper from "./components/Main_pageWrapper/Main_pageWrapper";
import {Routes, Route} from "react-router";
import AboutAdv1_and_Wrapper from "./components/AboutAdv1_and_Wrapper/AboutAdv1_and_Wrapper";

class App extends React.Component {
    constructor(props) {
        super(props);

        this.submitNote = this.submitNote.bind(this);

        this.state = {
            userInput: ``,
            shouldShowElem: false,
        };
    }

    submitNote() {
        this.setState({
            shouldShowElem: true,
        });
    }

    render() {
        return (
            <div className="wrapper">
                {/*<BackgroundHexWrapper/>*/}

                <div className="layout">
                    <div className="head">
                        <Header/>
                    </div>
                    <div className="main_pageWrapper">
                        <Main_pageWrapper/>
                    </div>

                    <div className="main_page">
                        <Main_page/>
                    </div>
                    <div className="aboutWrapper">
                        <AboutWrapper/>
                    </div>
                    <div className="about">
                        <About/>
                    </div>
                    <div className="aboutAdv1Wrapper">
                        <AboutAdv1Wrapper/>
                    </div>

                    <div className="aboutAdv1">
                        {/*<AboutAdv1/>*/}
                        <Routes>
                            <Route path="/" element={<AboutAdv1/>}/>
                            <Route path="/aboutAdv2" element={<AboutAdv2/>}/>
                            <Route path="/aboutAdv3" element={<AboutAdv3/>}/>
                        </Routes>
                    </div>

                    {/*<Routes>*/}
                    {/*    <Route path="/" className="aboutAdv1" element={<AboutAdv1 className="aboutAdv1"/>}/>*/}
                    {/*    <Route path="/" element={<AboutAdv2/>}/>*/}
                    {/*    <Route path="/" element={<AboutAdv3/>}/>*/}
                    {/*</Routes>*/}

                    {/*<Route path="aboutAdv1">*/}
                    {/*    <div className="aboutAdv1Wrapper">*/}
                    {/*        <AboutAdv1Wrapper/>*/}
                    {/*    </div>*/}
                    {/*    <div className="aboutAdv1">*/}
                    {/*        <AboutAdv1/>*/}
                    {/*    </div>*/}
                    {/*</Route>*/}
                    {/*<div className="aboutAdv2">*/}
                    {/*    <AboutAdv2/>*/}
                    {/*</div>*/}
                    {/*<div className="aboutAdv3">*/}
                    {/*    <AboutAdv3/>*/}
                    {/*</div>*/}
                    <div className="featureWrapper">
                        <FeatureWrapper/>
                    </div>
                    <div className="feature">
                        <Feature/>
                    </div>
                    <div className="roadmapWrapper1">
                        <RoadmapWrapper1/>
                    </div>
                    <div className="roadmapWrapper2">
                        <RoadmapWrapper2/>
                    </div>
                    <div className="roadmap">
                        <Roadmap/>
                    </div>
                    <div className="follow_usWrapper">
                        <Follow_usWrapper/>
                    </div>
                    <div className="follow_us">
                        <Follow_us/>
                    </div>
                    <div className="foot">
                        <Footer/>
                    </div>
                </div>
            </div>
        );
    }
}


export default App;
