import React from "react";
import s from './Main_page.module.css';
// import bg_mainPage from "../../images/bg_mainPage.png"
import ETERNAL_RIFTS from "../../images/ETERNAL_RIFTS.svg";

const Main_page = () => {
    return <div className={s.main_page}>

        {/*<div className={s.rectangle}></div>*/}

        {/*<div className={s.text1}>*/}
        {/*    <img src={ETERNAL_RIFTS} alt=""/>*/}
        {/*</div>*/}

        <div className={s.text1}>
            ETERNAL RIFTS
        </div>

        <div className={s.text2}>
            Пошаговая стратегия с элементами коллекционной <br/> карточной игры и своей уникальной стилистикой
        </div>

        <div className={s.text_wrapper_30}>
            Прогресс разработки
        </div>

        <div className={s.PROGRESS_BAR}/>
        <div className={s.rectangle_2}/>

        <div className={s.text_wrapper_31}>
            49%
        </div>
    </div>
}

export default Main_page;