import React, { useState } from "react";
import Axios from "axios";

function PostForm() {

    /*const url = "eternalrifts.ru/addemail.php";
    const [data, setData] = useState({
        email: "",
        ip: "",
        isMobile: "",
        browserInfo: ""
    })

   function submit(e) {
        e.preventDefault();
        Axios.post("eternalrifts.ru/addemail.php", {
            email: data.email,
            ip: data.ip,
            isMobile: data.isMobile,
            browserInfo: data.browserInfo
        })
            .then(res=>{
                console.log(res.data)
            })
   }

//      ?email=montece@inbox.ru
//      &ip=228.228.228.228
//      &isMobile=0
//      &browserInfo=noinfo

    function handle(e) {
        const newdata={...data}
        newdata[e.target.id] = e.target.value
        setData(newdata)
        console.log(newdata)


    }

    return (
        <div>
            <form onSubmit={(e)=>submit(e)}>
                <input onChange={(e) => handle(e)} id="email" value={data.email} placeholder="email" type="text"/>
                <input onChange={(e) => handle(e)} id="ip" value={data.ip} placeholder="ip" type="text"/>
                <input onChange={(e) => handle(e)} id="isMobile" value={data.isMobile} placeholder="isMobile" type="text"/>
                <input onChange={(e) => handle(e)} id="browserInfo" value={data.browserInfo} placeholder="browserInfo" type="text"/>
                <button>Submit</button>
            </form>
        </div>
    )*/
}

export default PostForm;

