import React from "react";
import s from './Roadmap.module.css';
import roadmapHex1 from "../../images/roadmapHex1.svg";
import roadmapHex2 from "../../images/roadmapHex2.svg";
import roadmapHex3 from "../../images/roadmapHex3.svg";
import roadmapHex4 from "../../images/roadmapHex4.svg";
import roadmapHex5 from "../../images/roadmapHex5.svg";
import featureHex1 from "../../images/featureHex1.svg";
import featureHex2 from "../../images/featureHex2.svg";
import featureHex3 from "../../images/featureHex3.svg";
import featureHex4 from "../../images/featureHex4.svg";

const Roadmap = () => {
    return <article id="roadmap">
        <div className={s.roadmap}>
            <div className={s.hex2}>
                <img src={roadmapHex2} />
            </div>
            <div className={s.hex3}>
                <img src={roadmapHex3} />
            </div>
            <div className={s.hex4}>
                <img src={roadmapHex4} />
            </div>
            <div className={s.hex5}>
                <img src={roadmapHex5} />
            </div>

            <div className={s.title}> Roadmap</div>

            <div className={s.head2}>Q2<br />2024</div>
            <div className={s.text2}>
                <li>Разработка геймплейного "ядра"</li>
                <li>Прокачка персонажа кибер-имплантами</li>
                <li>Внедрение биомов и их флоры, фауны, построек</li>
            </div>

            <div className={s.head3}>Q3<br />2024</div>
            <div className={s.text3}>
                <li>Закрытый бета-тест</li>
                <li>Доработка классов и навыков персонажа</li>
                <li>Система составления колод из карточек</li>
            </div>

            <div className={s.head4}>Q4<br />2024</div>
            <div className={s.text4}>
                <li>Открытый бета-тест</li>
                <li>Соревновательный движок</li>
                <li>QOL изменения и доработка UI интерфейса</li>
            </div>

            <div className={s.head5}>Q1<br />2025</div>
            <div className={s.text5}>
                <li>Выпуск игры</li>
                <li>Исправление багов и ошибок</li>
                <li>Изменения в балансе</li>
            </div>

            {/*<div className={s.head5}>Q4<br/>2023</div>*/}
            {/*<div className={s.text5}>*/}
            {/*    <li>Разработка геймплейного"ядра"</li>*/}
            {/*    <li>Система талантов персонажа</li>*/}
            {/*    <li>Прокачка персонажа кибер-имплантами</li>*/}
            {/*</div>*/}
        </div>
    </article>
}

export default Roadmap;