import React from "react";
import s from './AboutAdv3.module.css';
import aboutAdv3Hex1 from "../../images/aboutAdv3Hex1.svg";
import aboutAdv3Hex2 from "../../images/aboutAdv3Hex2.svg";
import aboutAdv3Hex3 from "../../images/aboutAdv3Hex3.svg";
import aboutAdv3Hex4 from "../../images/aboutAdv3Hex4.svg";
import {NavLink} from "react-router-dom";

const AboutAdv3 = () => {
    return <div className={s.aboutAdv3}>
        <div className={s.button1}>
            <button className={s.button} role="button">
                <NavLink to="/aboutAdv2"style={({ isActive, isPending, isTransitioning }) => {
                    return {
                        fontWeight: isActive ? "bold" : "",
                        color: isPending ? "red" : "white",
                        viewTransitionName: isTransitioning ? "slide" : "",
                    };
                }}>
                    О начале игры

                </NavLink>
            </button>
        </div>

        <div className={s.title}>
            Сражения
        </div>

        <div className={s.text2}>
            Сражение представляет собой карточную мини-игру, в которой
            участвуют <span>ваш отряд и существа противника</span>. Ваша задача -
            уничтожить карточку персонажа противника.<br/><br/>
            Каждый ход вам выдаются карточки в руку, которые можно выложить
            на поле за камни пустоты. Источником карточек является ваша колода,
            которую вы собирали, исследуя карту.<br/><br/>
            Добиться успеха в бою можно с помощью других игроков,
            а также используя различные тактические решения:
        </div>

        <div className={s.hex1}>
            <img src={aboutAdv3Hex1} alt=""/>
        </div>
        <div className={s.textHex1}>
            Своевременное выкладывание карточек на поле
        </div>

        <div className={s.hex2}>
            <img src={aboutAdv3Hex2} alt=""/>
        </div>
        <div className={s.textHex2}>
            Анализ колоды противника
        </div>

        <div className={s.hex3}>
            <img src={aboutAdv3Hex3} alt=""/>
        </div>
        <div className={s.textHex3}>
            Грамотный выбор цели для атаки
        </div>

        <div className={s.hex4}>
            <img src={aboutAdv3Hex4} alt=""/>
        </div>
        <div className={s.textHex4}>
            Использование способностей и предметов
        </div>

        <div className={s.rectangle2}></div>
        <div className={s.text5}>
            Вы готовы уничтожить Рифтов и стать героем мультивселенной?
            Тогда вперед, спасем этот мир!
        </div>
        <div className={s.text6}>
            This is Eternal Rifts!
        </div>
    </div>
}

export default AboutAdv3;