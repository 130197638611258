import React from "react";
import s from './Footer.module.css';
import logo_montece_gaming from "../../images/logo_montece_gaming.svg";

const Footer = () => {
    return <div className={s.foot}>
        <div className={s.data}>
            2024
        </div>

        <div className={s.text1}>
            <a href="http://www.montecegaming.com" target="_blank">
                MONTECE
            </a>
        </div>

        <div className={s.logo}>
            <a href="http://www.montecegaming.com" target="_blank">
                <img src={logo_montece_gaming} alt="logo_montece" />
            </a>
        </div>

        <div className={s.text2}>
            <a href="http://www.montecegaming.com" target="_blank">
                GAMING
            </a>
        </div>
    </div>

}
export default Footer;